import axios from "axios"

<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar deuda</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />
          <v-col cols="12" lg="6">
              <v-text-field
                v-model="name_customer"                
                :counter="190"
                :disabled="true"
                prepend-icon="mdi-store"
                label="Nombre de Empresa *"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                v-model="name_plan"              
                :counter="190"
                :disabled="true"
                prepend-icon="mdi-text-box-outline"
                label="Plan *"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                v-model="billing_period"                
                :counter="190"
                :disabled="true"
                prepend-icon="mdi-calendar-month-outline"
                label="Día de facturación *"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="6">
              <v-text-field
                v-model="price_debt"                
                :counter="190"
                prepend-icon="mdi-cash"
                label="Importe de deuda *"
              ></v-text-field>
            </v-col>

          
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="9"></v-col>
            <v-col cols="12" lg="3">
              <v-btn color="default" class="mr-4" @click="cancel">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="update"> Actualizar </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({


   contract:"",
   customer:"",

    select: "",

    name_customer:"",
    name_plan:"",
    billing_period:"",
    price_debt:"",
  
    id_customer:"",
    id_plan:"",
    id_billing_period:"",
    id_contract:"",
    id_debt:"",
  
 
  }),

  mounted() {
   
    this.chargeData(); 
    
  },
  methods: {

    cancel() {
      setTimeout(() => this.$router.push({ name: "debt" }), 10);
    },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
        
      };
     
      axios
        .post("/debt", request)
        .then((response) => {
          this.price_debt = response.data.price;
          this.id_contract = response.data.id_contract;
          console.log(this.price_debt);
          console.log("id contrato obtenido");
          console.log(this.id_contract);
          
          this.getContractById();

          
          
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al cargar datos de deuda");
        })
        
    },
    
    getContractById(){
      let request={
        id:this.id_contract,
      };
      console.log("este es el request");
      console.log(request);
      axios
        .post("/contract",request)
        .then((response)=>{
              this.contract=response.data;
              this.id_customer=this.contract.id_customer;
              this.id_plan=this.contract.id_plan;
              this.id_billing_period=this.contract.id_billing_periods;
              console.log("Estos son los contratos");
              console.log(this.contract.id);
              console.log(this.contract.id_customer);
              console.log(this.id_plan);
              console.log(this.id_billing_period);
              this.getCustomerById();
              this.getPlanById();
              this.getBillingPeriodById();

         }).
        catch((err)=>{
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener contrato de cliente");
        });
      
    },
    
    getCustomerById(){
      let request={
        id:this.id_customer,
      };
      console.log("NOmbre de la empresa");
      console.log(this.id_customer);
      axios
        .post("/customer",request)
        .then((response)=>{
              this.customer=response.data;
              this.name_customer=this.customer.legal_name;
              console.log("obtener nombre empresa");
              console.log(this.name_customer);          
         }).
        catch((err)=>{
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener cliente");
        });
      
    },


    getPlanById(){
      let request={
        id:this.id_plan,
      };
      console.log("Nombre del plan");
      console.log(this.id_plan);
      axios
        .post("/plan",request)
        .then((response)=>{
              this.name_plan=response.data.name;
              console.log("obtener nombre plan");
              console.log(this.name_plan);          
         }).
        catch((err)=>{
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener plan");
        });
      
    },

    
    getBillingPeriodById(){
      let request={
        id:this.id_billing_period,
      };
      console.log("Nombre Periodo de facturacion");
      console.log(this.id_billing_period);
      axios
        .post("/billing-period",request)
        .then((response)=>{
              this.billing_period=response.data.billing_period;
              console.log("obtener nombre plan");
              console.log(this.id_billing_period);          
         }).
        catch((err)=>{
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener día de facturación");
        });
      
    },
   
    

    update() {
      console.log("actualizando plan");

      let request = {
        id: this.$route.params.id,
        price:this.price_debt,
              
      };
      console.log(request);
     

      axios
        .post("/update-debt", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "debt" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al actualizar deuda");
          setTimeout(() => this.$router.push({ name: "debt" }), 4000);
        });
    },
  
    },
};
</script>